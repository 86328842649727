import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Row, Col, Grid } from '@sumup/circuit-ui/legacy';
import reduce from 'lodash/fp/reduce';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';

import ExtendedProduct from './components/ExtendedProduct';
import {
  checkForPromoInProducts,
  checkForBillingCycleInProducts,
} from './ExtendedProductComparisonService';
import { PRODUCT_MIN_WIDTH, PRODUCT_MAX_WIDTH } from './constants';

import ProductsContext from '~/shared/providers/ProductsContext';
import { sortByPrice } from '~/shared/services/products';
import dataSelector from '~/shared/util/data-selector';
import { mapVariations } from '~/shared/services/optimizely/OptimizelyVariationsService';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';

const DATA_SELECTOR = 'extended_product_comparison';

const gridStyles = ({ theme }) => css`
  ${theme.mq.kilo} {
    max-width: initial;
  }
`;
const StyledGrid = styled(Grid)(gridStyles);

const mobileRowVerticalLayout = ({ theme, verticalLayout }) =>
  verticalLayout &&
  css`
    ${theme.mq.untilKilo} {
      flex-direction: column;
    }
  `;

const rowStyles = css`
  display: flex;
  justify-content: center;
`;

const horizontalScrollingStyles = `
  justify-content: start;
  position: relative;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
`;

const rowHorizontalLayout = ({
  theme,
  verticalLayout,
  horizontalScrollBreakpoint,
}) =>
  !verticalLayout &&
  css`
    ${theme.mq.untilKilo} {
      ${horizontalScrollingStyles}
    }
    ${horizontalScrollBreakpoint &&
    `@media (max-width: ${horizontalScrollBreakpoint}px) {
      ${horizontalScrollingStyles}
    }`}
  `;

const StyledRow = styled(Row)(
  rowStyles,
  rowHorizontalLayout,
  mobileRowVerticalLayout,
);

const mobileColVerticalLayout = ({ theme, verticalLayout }) =>
  verticalLayout &&
  css`
    ${theme.mq.untilKilo} {
      margin: ${theme.spacings.mega} auto;
    }
  `;

const colHorizontalLayoutResponsiveness = ({ theme, verticalLayout }) => css`
  max-width: ${PRODUCT_MAX_WIDTH}px;
  min-width: ${PRODUCT_MIN_WIDTH}px;
  ${!verticalLayout &&
  `${theme.mq.untilKilo} {
      width: 75%;
      min-width: auto;
    }`}
`;

const colStyles = ({ theme }) => css`
  ${theme.mq.untilMega} {
    margin: ${theme.spacings.kilo} 0;
    flex: 0 0 auto;
  }
`;

const StyledCol = styled(Col)(
  colStyles,
  colHorizontalLayoutResponsiveness,
  mobileColVerticalLayout,
);

/**
 * ExtendedProductComparison to compare different product features.
 */
function ExtendedProductComparison({
  comparedProducts: originalComparedProducts = [],
  verticalLayout = false,
}) {
  const { products = {} } = useContext(ProductsContext);
  const { experiments } = useOptimizelyData();
  const comparedProducts = mapVariations(originalComparedProducts, experiments);

  if (isEmpty(comparedProducts)) {
    return null;
  }

  const productsToCompare = reduce(
    (
      allProducts,
      {
        product: productFromProps,
        features,
        billingCycleLabel,
        hideCardSchemes,
        primaryProductButtonUrl,
        primaryProductButtonLabel,
        primaryProductButtonTrackingId = 'button_primary@extended_product',
        primaryProductButtonOptimizelyFullStackClickEvents,
        secondaryProductButtonUrl,
        secondaryProductButtonLabel,
        secondaryProductButtonTrackingId = 'button_secondary@extended_product',
        secondaryProductButtonOptimizelyFullStackClickEvents,
        contentType: contentEntryType,
        id: contentEntryId,
      },
    ) => {
      const product = get(productFromProps?.productId, products) || {};

      if (isEmpty(product)) {
        return allProducts;
      }

      return allProducts.concat({
        ...product,
        features,
        billingCycleLabel,
        hideCardSchemes,
        primaryProductButtonUrl,
        primaryProductButtonLabel,
        primaryProductButtonTrackingId,
        primaryProductButtonOptimizelyFullStackClickEvents,
        secondaryProductButtonUrl,
        secondaryProductButtonLabel,
        secondaryProductButtonTrackingId,
        secondaryProductButtonOptimizelyFullStackClickEvents,
        contentEntryType,
        contentEntryId,
      });
    },
    [],
    comparedProducts,
  );

  const sortedProducts = sortByPrice(productsToCompare);
  const productCount = sortedProducts.length;
  const kiloColSpan = Math.round(12 / productCount);
  const horizontalScrollBreakpoint = PRODUCT_MIN_WIDTH * productCount;
  const someHasPromo = checkForPromoInProducts(sortedProducts);
  const someHasBillingCycle = checkForBillingCycleInProducts(sortedProducts);

  return (
    <StyledGrid
      data-selector={dataSelector('section', DATA_SELECTOR)}
      data-elbcontext="component:extended_product_comparison"
    >
      <StyledRow
        data-selector={dataSelector('products_row', DATA_SELECTOR)}
        verticalLayout={verticalLayout}
        horizontalScrollBreakpoint={horizontalScrollBreakpoint}
      >
        {sortedProducts.map(
          ({ contentEntryType, contentEntryId, ...product }) => (
            <StyledCol
              span={{ default: verticalLayout ? 12 : 8, kilo: kiloColSpan }}
              skip={{ default: 0 }}
              key={product.productId}
              data-selector={dataSelector('product_column', DATA_SELECTOR)}
              verticalLayout={verticalLayout}
            >
              <ExtendedProduct
                product={product}
                enablePromoHeight={someHasPromo}
                enableBillingCycleHeight={someHasBillingCycle}
                trackingContentEntry={{
                  contentType: contentEntryType,
                  contentEntryName: product.productName,
                  contentEntryId,
                }}
              />
            </StyledCol>
          ),
        )}
      </StyledRow>
    </StyledGrid>
  );
}

ExtendedProductComparison.propTypes = {
  /**
   * Products to compare
   */
  comparedProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Use vertical layout on mobile
   */
  verticalLayout: PropTypes.bool,
};

/**
 * @component
 */
export default ExtendedProductComparison;
