import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Headline, Card, Button } from '@sumup/circuit-ui';
import isEmpty from 'lodash/fp/isEmpty';
import Tagger from '@elbwalker/tagger';

import { calcPricingContainerHeight } from '../../ExtendedProductComparisonService';

import Image from '~/shared/components/Image';
import CardSchemesCollection from '~/shared/components/CardSchemesCollection';
import Link from '~/shared/components/Link';
import RichText from '~/shared/components/RichText';
import * as inline from '~/shared/components/RichText/configs/inline';
import Icon from '~/shared/components/icons/Icon';
import dataSelector from '~/shared/util/data-selector';
import { trackingContentEntryPropType } from '~/shared/util/shared-prop-types';
import Price from '~/shared/components/Price';
import { PRICE_FORMATS } from '~/shared/constants/price';
import {
  mapVariations,
  getVariationComponent,
} from '~/shared/services/optimizely/OptimizelyVariationsService';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import { ACTIONS, ENTITIES, TRIGGERS } from '~/shared/constants/tracking';
import { AddToCart } from '~/domains/shop/components/AddToCart';
import { SHOP_INTEGRATION_FEATURE_TOGGLE } from '~/shared/services/optimizely/constants';

const DATA_SELECTOR = 'extended_product_comparison';
const tagger = Tagger();

const productStyles = ({ theme }) => css`
  position: relative;
  text-align: center;
  height: 100%;
  justify-content: flex-start;
  padding: ${theme.spacings.mega} 0;
`;

const Product = styled(Card)(productStyles);

const iconStyles = ({ theme }) => css`
  height: auto;
  margin-right: ${theme.spacings.byte};
`;

const StyledIcon = styled(Icon)(iconStyles);

const uspLabelStyles = ({ theme }) => css`
  font-size: ${theme.typography.body.one.fontSize};
  line-height: ${theme.typography.body.one.lineHeight};
  padding: 0 ${theme.spacings.kilo};
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  max-width: 100%;
`;

const UspLabel = styled('div')(uspLabelStyles);

const billingCycleLabelStyles = ({ theme }) => css`
  font-size: ${theme.typography.body.one.fontSize};
  font-weight: ${theme.fontWeight.bold};
  line-height: ${theme.typography.body.one.lineHeight};
`;

const BillingCycleLabel = styled('div')(billingCycleLabelStyles);

const priceContainerStyles = ({ theme, minHeight }) => css`
  margin-bottom: ${theme.spacings.kilo};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: ${minHeight}px;
`;

const PriceContainer = styled('div')(priceContainerStyles);

const priceInnerContainerStyles = css`
  margin: 0;
`;

const PriceInnerContainer = styled('div')(priceInnerContainerStyles);

const headerStyles = ({ theme }) => css`
  color: var(--cui-fg-normal);
  margin-bottom: ${theme.spacings.giga};
`;

const Header = styled(Headline)(headerStyles);

const imageStyles = ({ theme }) => css`
  margin-bottom: ${theme.spacings.mega};
`;

const StyledImage = styled(Image)(imageStyles);

const featureStyles = ({ theme }) => css`
  display: block;
  text-align: left;
  padding-top: ${theme.spacings.mega};
  font-size: ${theme.typography.subHeadline.fontSize};
  line-height: ${theme.typography.subHeadline.lineHeight};
  white-space: normal;
  :not(:last-child) {
    padding-bottom: ${theme.spacings.mega};
    border-bottom: ${theme.borderWidth.mega} solid var(--cui-border-subtle);
  }
`;

const featureIconStyles = ({ hasIcon }) =>
  hasIcon &&
  css`
    display: flex;
    align-items: center;
  `;

const Feature = styled('div')(featureStyles, featureIconStyles);

const groupStyles = ({ theme }) => css`
  display: flex;
  flex-direction: column;
  border-top: ${theme.borderWidth.mega} solid var(--cui-border-subtle);
  margin-bottom: ${theme.spacings.mega};
  &:last-child {
    margin-bottom: 0;
  }
`;

const Group = styled('div')(groupStyles);

const groupTopStyles = ({ theme }) => css`
  padding: 0 ${theme.spacings.giga};
  border-top: 0;
  margin-top: 0;

  ${theme.mq.untilMega} {
    padding: 0 ${theme.spacings.mega};
  }
`;

const GroupTop = styled('div')(groupStyles, groupTopStyles);

const subGroupStyles = ({ theme }) => css`
  display: flex;
  flex-direction: column;
  padding: 0 ${theme.spacings.giga};

  ${theme.mq.untilMega} {
    padding: 0 ${theme.spacings.mega};
  }
`;

const groupBottomStyles = css`
  margin-top: auto;
`;

const GroupBottom = styled('div')(groupStyles, groupBottomStyles);

const SubGroup = styled('div')(subGroupStyles);

const primaryButtonStyles = ({ theme }) => css`
  margin-top: ${theme.spacings.mega};
  margin-bottom: 2px;
`;

const StyledPrimaryButton = styled(Button)(primaryButtonStyles);

const secondaryButtonStyles = ({ theme }) => css`
  margin-top: ${theme.spacings.kilo};
  margin-bottom: -${theme.spacings.bit};
  padding-top: 0;
  padding-bottom: 0;
`;

const StyledSecondaryButton = styled(Button)(secondaryButtonStyles);

/**
 * ExtendedProduct displaying product features information.
 */
const ExtendedProduct = ({
  product,
  product: {
    image,
    productName,
    usp,
    billingCycleLabel,
    cardSchemes,
    hideCardSchemes = false,
    features: originalFeatures = [],
    primaryProductButtonUrl,
    primaryProductButtonLabel,
    primaryProductButtonTrackingId,
    primaryProductButtonOptimizelyFullStackClickEvents,
    secondaryProductButtonUrl,
    secondaryProductButtonLabel,
    secondaryProductButtonTrackingId,
    secondaryProductButtonOptimizelyFullStackClickEvents,
  } = {},
  trackingContentEntry = {},
  enablePromoHeight = false,
  enableBillingCycleHeight = false,
}) => {
  const { experiments } = useOptimizelyData();
  const features = mapVariations(originalFeatures, experiments);
  const pricingContainerMinHeight = calcPricingContainerHeight(
    enablePromoHeight,
    enableBillingCycleHeight,
  );

  const { featureToggles } = useOptimizelyData();
  const shopIntegration = Boolean(
    featureToggles[SHOP_INTEGRATION_FEATURE_TOGGLE],
  );

  return (
    <Product
      data-elbcontext="component:extended_product"
      {...tagger.entity(ENTITIES.PRODUCT)}
      {...tagger.action(TRIGGERS.VISIBLE, ACTIONS.VIEW)}
    >
      <GroupTop>
        <StyledImage
          src={image}
          alt={productName}
          width={800}
          height={800}
          srcSet={[
            {
              src: image,
              size: '400w',
              height: 400,
              width: 400,
            },
            {
              src: image,
              size: '800w',
              height: 800,
              width: 800,
            },
          ]}
          sizes="(min-width: 600px) 40vw, 100vw"
          contentful={{ fit: 'fill' }}
          data-selector={dataSelector('product_image', DATA_SELECTOR)}
        />
        <Header as="p" size="four">
          {productName}
        </Header>
        <PriceContainer minHeight={pricingContainerMinHeight}>
          <PriceInnerContainer>
            <Price product={product} format={PRICE_FORMATS.SIZE_32} />
            {!isEmpty(billingCycleLabel) && (
              <BillingCycleLabel>{billingCycleLabel}</BillingCycleLabel>
            )}
          </PriceInnerContainer>
        </PriceContainer>
        {!isEmpty(usp) && <UspLabel>{usp}</UspLabel>}

        {!isEmpty(primaryProductButtonLabel) && !shopIntegration && (
          <Link
            href={primaryProductButtonUrl}
            trackingId={primaryProductButtonTrackingId}
            optimizelyFullStackClickEvents={
              primaryProductButtonOptimizelyFullStackClickEvents
            }
            trackingContentEntry={trackingContentEntry}
          >
            <StyledPrimaryButton
              data-selector={dataSelector('button_primary', DATA_SELECTOR)}
              stretched
              variant="primary"
            >
              {primaryProductButtonLabel}
            </StyledPrimaryButton>
          </Link>
        )}
        {!isEmpty(primaryProductButtonLabel) && shopIntegration && (
          <AddToCart
            product={product}
            label={primaryProductButtonLabel}
            fullWidth
            variant="primary"
          />
        )}
      </GroupTop>
      <Group>
        <SubGroup>
          {features.map(({ content, icon: originalIcon }, i) => {
            const icon =
              originalIcon && getVariationComponent(originalIcon, experiments);
            const hasIcon = !!(icon && icon.image);

            return (
              <Feature key={i} hasIcon={hasIcon}>
                {hasIcon && (
                  <StyledIcon
                    src={icon.image.file.url}
                    size={Icon.KILO}
                    data-selector={dataSelector('feature_icon', DATA_SELECTOR)}
                  />
                )}

                <RichText
                  richText={content}
                  renderNode={inline.createRenderNode()}
                  renderMark={inline.createRenderMark()}
                />
              </Feature>
            );
          })}
        </SubGroup>
      </Group>
      {!isEmpty(cardSchemes) && !hideCardSchemes && (
        <Group>
          <SubGroup>
            <Feature
              data-selector={dataSelector(
                'card_schemes_section',
                DATA_SELECTOR,
              )}
            >
              <CardSchemesCollection iconIds={cardSchemes} />
            </Feature>
          </SubGroup>
        </Group>
      )}
      {!isEmpty(secondaryProductButtonLabel) && (
        <GroupBottom>
          <SubGroup>
            <Link
              href={secondaryProductButtonUrl}
              trackingId={secondaryProductButtonTrackingId}
              optimizelyFullStackClickEvents={
                secondaryProductButtonOptimizelyFullStackClickEvents
              }
              trackingContentEntry={trackingContentEntry}
            >
              <StyledSecondaryButton
                data-selector={dataSelector('button_secondary', DATA_SELECTOR)}
                variant="tertiary"
                size={'kilo'}
              >
                {secondaryProductButtonLabel}
              </StyledSecondaryButton>
            </Link>
          </SubGroup>
        </GroupBottom>
      )}
    </Product>
  );
};

ExtendedProduct.propTypes = {
  /**
   * Product item
   */
  product: PropTypes.object.isRequired,
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,
  /**
   * Circuit-UI theme
   */
  theme: PropTypes.object,
  /**
   * Adjust the height of every product pricing section in the list
   * when at least one product has promo or billing cycle label
   */
  enablePromoHeight: PropTypes.bool,
  enableBillingCycleHeight: PropTypes.bool,
};

/**
 * @component
 */
export default ExtendedProduct;
