import isEmpty from 'lodash/fp/isEmpty';

import { PRICING_HEIGHT_DEFAULT, PRICING_HEIGHT_STEP } from './constants';

export function checkForPromoInProducts(sortedProducts = []) {
  return !!sortedProducts.find((product) => product.hasPromo);
}

export function checkForBillingCycleInProducts(sortedProducts = []) {
  return !!sortedProducts.find(
    (product) => !isEmpty(product.billingCycleLabel),
  );
}

export function calcPricingContainerHeight(
  enablePromoHeight,
  enableBillingCycleHeight,
) {
  let height = PRICING_HEIGHT_DEFAULT;

  if (enablePromoHeight) {
    height += PRICING_HEIGHT_STEP;
  }

  if (enableBillingCycleHeight) {
    height += PRICING_HEIGHT_STEP;
  }

  return height;
}
